<style lang="scss">
.update-group-page {
}
</style>

<template>
  <div class="update-group-page">
    <b-card class="">

      <div class="d-flex align-items-baseline border-bottom">
        <h3 v-if="group" class=" mb-2">Edit: {{ group.name }} (v{{ group.version }})</h3>
        <div class="ui-spacer" />
        <router-link v-if="group" class="btn btn-warning mr-1" tag="button" :to="{ name: 'group-view', params: { uid: group.uid } }">
          Cancel
        </router-link>

        <b-button v-if="group" :class="{'disabled': !checkFormValid()}" variant="primary" @click="updateGroup(false)">
          Update group
        </b-button>
      </div>

      <section v-if="!loading">
        <section v-if="group" class="mb-2">
          <section>
            <div class="row mt-2">
              <div class="col-md-4">
                <label>Group Name</label>
                <b-form-input v-model="group.name" required class="mb-1" placeholder="Enter name*" />

                <label class="mr-sm-2" for="system-updates">System Updates Policy</label>
                <b-form-select id="system-updates" v-model="group.system_update" class="mb-1">
                  <b-form-select-option value="WINDOWED">Windowed - Between 1am and 3am</b-form-select-option>
                  <b-form-select-option value="AUTOMATIC">Automatic, as soon as possible</b-form-select-option>
                  <b-form-select-option value="POSTPONE">Postponed, up to 30 days delay</b-form-select-option>
                </b-form-select>

                <label class="mr-sm-2" for="status_bar">
                  Status Bar Options
                  <InsecureWarning :insecure-fields="group.insecure_fields" :field-name="'status_bar'" :field="group.status_bar" />
                </label>

                <b-form-select id="status_bar" v-model="group.status_bar" class="mb-1">
                  <b-form-select-option value="SYSTEM_INFO_ONLY">System Info Only</b-form-select-option>
                  <b-form-select-option value="NOTIFICATIONS_AND_SYSTEM_INFO_ENABLED">Enabled</b-form-select-option>
                  <b-form-select-option value="NOTIFICATIONS_AND_SYSTEM_INFO_DISABLED">Disabled</b-form-select-option>
                </b-form-select>

                <label class="mr-sm-2" for="system_navigation">
                  System Navigation
                  <InsecureWarning :insecure-fields="group.insecure_fields" :field-name="'system_navigation'" :field="group.system_navigation" />
                </label>
                <b-form-select id="system_navigation" v-model="group.system_navigation" class="mb-1">
                  <b-form-select-option value="HOME_BUTTON_ONLY">Home Button Only</b-form-select-option>
                  <b-form-select-option value="NAVIGATION_ENABLED">Enabled</b-form-select-option>
                  <b-form-select-option value="NAVIGATION_DISABLED">Disabled</b-form-select-option>
                </b-form-select>

                <div class="mb-1">
                  <label>Screen timeout (in seconds)</label>
                  <validation-provider v-slot="validationContext" name="Screen timeout (in seconds)" :rules="{ required: true, min_value:10, max_value:600 }" :custom-messages="{min_value: 'The screen timeout should be between 10 and 600 seconds', max_value: 'The screen timeout should be between 10 and 600 seconds'}">
                    <b-form-input v-model="group.time_to_lock_seconds" type="number"
                                  :state="getValidationState(validationContext)" placeholder="Enter the screen timeout" :number="true"
                    />

                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>

              </div>
              <div class="col-md-4 mt-2">

                <b-form-checkbox v-model="group.factory_reset_disabled" name="check-button" switch>
                  Disable Factory Reset (Recommended)
                  <InsecureWarning :insecure-fields="group.insecure_fields" :field-name="'factory_reset_disabled'" :field="group.factory_reset_disabled" />
                </b-form-checkbox>

                <b-form-checkbox v-model="group.settings_disabled" name="check-button" switch>
                  Disable Device Settings Access (Recommended)
                  <InsecureWarning :insecure-fields="group.insecure_fields" :field-name="'settings_disabled'" :field="group.settings_disabled" />
                </b-form-checkbox>

                <b-form-checkbox v-model="group.screen_capture_disabled" name="check-button" switch>
                  Disable Screen Capture
                  <InsecureWarning :insecure-fields="group.insecure_fields" :field-name="'screen_capture_disabled'" :field="group.screen_capture_disabled" />
                </b-form-checkbox>

                <b-form-checkbox v-model="group.camera_disabled" name="check-button" switch>
                  Disable Device Camera
                  <InsecureWarning :insecure-fields="group.insecure_fields" :field-name="'camera_disabled'" :field="group.camera_disabled" />
                </b-form-checkbox>

                <b-form-checkbox v-model="group.power_button_menu_disabled" name="check-button" switch>
                  Disable Power Menu (Reboot and power off)
                  <InsecureWarning :insecure-fields="group.insecure_fields" :field-name="'power_button_menu_disabled'" :field="group.power_button_menu_disabled" />
                </b-form-checkbox>

                <b-form-checkbox v-model="group.create_windows_disabled" name="check-button" switch>
                  Disable Window Creation
                  <b-button id="popover-button-default" variant="flat-primary" class="btn-icon p-0">
                    <feather-icon icon="InfoIcon" />
                  </b-button>
                  <InsecureWarning :insecure-fields="group.insecure_fields" :field-name="'create_windows_disabled'" :field="group.create_windows_disabled" />
                </b-form-checkbox>

                <b-popover target="popover-button-default" triggers="focus" placement="top">
                  <template #title>
                    <span>Disable Window Creation</span>
                  </template>
                  <span>Stops applications from being able to create toasts and windows on top of others</span>
                </b-popover>

                <b-form-checkbox v-model="group.status_reporting_settings" name="check-button" switch>
                  Enable Device Status reports
                  <InsecureWarning :insecure-fields="group.insecure_fields" :field-name="'status_reporting_settings'" :field="group.status_reporting_settings" />
                </b-form-checkbox>

                <b-form-checkbox v-model="group.youtube_enabled" name="check-button" switch>
                  Enable Purple Play
                  <InsecureWarning :insecure-fields="group.insecure_fields" :field-name="'youtube_enabled'" :field="group.youtube_enabled" />
                </b-form-checkbox>

                <b-form-checkbox v-model="group.news_feeds_enabled" name="check-button" switch>
                  Enable News Feeds
                  <InsecureWarning :insecure-fields="group.insecure_fields" :field-name="'news_feeds_enabled'" :field="group.news_feeds_enabled" />
                </b-form-checkbox>

              </div>
              <div class="col-md-4 mt-2">

                <b-form-checkbox v-model="group.allow_firmware_recovery" name="check-button" switch>
                  Allow Firmware Recovery
                  <InsecureWarning :insecure-fields="group.insecure_fields" :field-name="'allow_firmware_recovery'" :field="group.allow_firmware_recovery" />
                </b-form-checkbox>

                <b-form-checkbox v-model="group.bluetooth_config_enabled" name="check-button" switch>
                  Enable Bluetooth Config
                  <InsecureWarning :insecure-fields="group.insecure_fields" :field-name="'bluetooth_config_enabled'" :field="group.bluetooth_config_enabled" />
                </b-form-checkbox>

                <b-form-checkbox v-model="group.vpn_config_disabled" name="check-button" switch>
                  Enable VPN Config
                  <InsecureWarning :insecure-fields="group.insecure_fields" :field-name="'vpn_config_disabled'" :field="group.vpn_config_disabled" />
                </b-form-checkbox>

                <b-form-checkbox v-model="group.share_location_enabled" name="check-button" switch>
                  Enable Share Location
                  <InsecureWarning :insecure-fields="group.insecure_fields" :field-name="'share_location_enabled'" :field="group.share_location_enabled" />
                </b-form-checkbox>

                <b-form-checkbox v-model="group.usb_file_transfer_enabled" name="check-button" switch>
                  Enable USB File Transfer
                  <InsecureWarning :insecure-fields="group.insecure_fields" :field-name="'usb_file_transfer_enabled'" :field="group.usb_file_transfer_enabled" />
                </b-form-checkbox>

                <b-form-checkbox v-model="group.default_keyboard_enabled" name="check-button" switch>
                  Enable Default Keyboard
                  <b-button id="popover-default-keyboard-enabled" variant="flat-primary" class="btn-icon p-0">
                    <feather-icon icon="InfoIcon" />
                  </b-button>
                  <InsecureWarning :insecure-fields="group.insecure_fields" :field-name="'default_keyboard_enabled'" :field="group.default_keyboard_enabled" />
                </b-form-checkbox>

                <b-form-checkbox v-model="group.debugging_features_allowed" name="check-button" switch>
                  Enable Debugging features
                  <InsecureWarning :insecure-fields="group.insecure_fields" :field-name="'debugging_features_allowed'" :field="group.debugging_features_allowed" />
                </b-form-checkbox>

                <b-popover target="popover-default-keyboard-enabled" triggers="focus" placement="top">
                  <template #title>
                    <span>Enable the Default Keyboard</span>
                  </template>
                  <span>On a Samsung Knox device we use a simple keyboard as the Samsung version has lots of plugins. If you're happy with the risk you can enable it here.</span>
                </b-popover>

              </div>
            </div>
          </section>

          <section v-if="$can('Manage', 'All')">
            <h3 class="border-bottom mb-1 mt-2 pb-1">Advanced features</h3>
            <p>These advanced features are used for debugging and testing. These are only available to set by super admins.</p>

            <div class="row mt-2">
              <div class="col-md-4">
                <b-form-checkbox v-model="group.disable_purple_launcher" name="check-button" switch>
                  Disable Purple Launcher
                  <b-button id="popover-disbale-purple-launcher" variant="flat-primary" class="btn-icon p-0">
                    <feather-icon icon="InfoIcon" />
                  </b-button>
                  <InsecureWarning :insecure-fields="group.insecure_fields" :field-name="'disable_purple_launcher'" :field="group.disable_purple_launcher" />
                </b-form-checkbox>

                <b-popover target="popover-disbale-purple-launcher" triggers="focus" placement="top">
                  <template #title>
                    <span>Disable Purple Launcher</span>
                  </template>
                  <span>Turns off kiosk mode, so the agent is no longer running in the forefront. You can't enrol a device into a group if the purple launcher is disabled.</span>
                </b-popover>

                <b-form-checkbox v-model="group.keyguard_disabled" name="check-button" switch>
                  Disable Keyguard
                  <b-button id="popover-disable-keyguard" variant="flat-primary" class="btn-icon p-0">
                    <feather-icon icon="InfoIcon" />
                  </b-button>
                  <InsecureWarning :insecure-fields="group.insecure_fields" :field-name="'keyguard_disabled'" :field="group.keyguard_disabled" />
                </b-form-checkbox>

                <b-popover target="popover-disable-keyguard" triggers="focus" placement="top">
                  <template #title>
                    <span>Disable Keyguard</span>
                  </template>
                  <span>Disables the keyguard, but only works if there is no password on the device</span>
                </b-popover>

                <b-form-checkbox v-model="group.ask_credentials_on_boot" name="check-button" switch>
                  Ask for Credentials on Boot
                  <InsecureWarning :insecure-fields="group.insecure_fields" :field-name="'ask_credentials_on_boot'" :field="group.ask_credentials_on_boot" />
                </b-form-checkbox>


                <b-form-checkbox v-model="group.wifi_config_enabled" name="check-button" disabled switch>
                  Enable Wifi Config
                  <b-button id="popover-wifi-config-enabled" variant="flat-primary" class="btn-icon p-0">
                    <feather-icon icon="InfoIcon" />
                  </b-button>
                  <InsecureWarning :insecure-fields="group.insecure_fields" :field-name="'wifi_config_enabled'" :field="group.wifi_config_enabled" />
                </b-form-checkbox>

                <b-popover target="popover-wifi-config-enabled" triggers="focus" placement="top">
                  <template #title>
                    <span>Enable Wifi Config</span>
                  </template>
                  <span>Disabling this setting will change the `Enable Network Escape Hatch` to true in android. This is an android `Feature` that we can't override.</span>
                </b-popover>

                <b-form-checkbox v-model="group.network_escape_hatch_enabled" name="check-button" switch>
                  Enable Network Escape Hatch
                  <InsecureWarning :insecure-fields="group.insecure_fields" :field-name="'network_escape_hatch_enabled'" :field="group.network_escape_hatch_enabled" />
                </b-form-checkbox>
              </div>
              <div class="col-md-4">


                <b-form-checkbox v-model="group.firmware_auto_update_allowed" name="check-button" switch>
                  Allow Knox Firmware Auto Update
                  <InsecureWarning :insecure-fields="group.insecure_fields" :field-name="'firmware_auto_update_allowed'" :field="group.firmware_auto_update_allowed" />
                </b-form-checkbox>

                <b-form-checkbox v-model="group.install_unknown_sources_allowed" name="check-button" switch>
                  Enable Install From Unknown Sources
                  <InsecureWarning :insecure-fields="group.insecure_fields" :field-name="'install_unknown_sources_allowed'" :field="group.install_unknown_sources_allowed" />
                </b-form-checkbox>

                <b-form-checkbox v-model="group.mount_physical_media_enabled" name="check-button" switch>
                  Enable Mount Physical Media
                  <InsecureWarning :insecure-fields="group.insecure_fields" :field-name="'mount_physical_media_enabled'" :field="group.mount_physical_media_enabled" />
                </b-form-checkbox>

                <b-form-checkbox v-model="group.no_pin_enabled" name="check-button" switch>
                  Enable "No Pin" for device
                  <b-button id="popover-no_pin_enabled" variant="flat-primary" class="btn-icon p-0">
                    <feather-icon icon="InfoIcon" />
                  </b-button>
                  <InsecureWarning :insecure-fields="group.insecure_fields" :field-name="'no_pin_enabled'" :field="group.no_pin_enabled" />
                </b-form-checkbox>

                <b-popover target="popover-no_pin_enabled" triggers="focus" placement="top">
                  <template #title>
                    <span>No Pin</span>
                  </template>
                  <span>
                    WARNING: This will set the password requirement for the tablet to NONE and will not automatically send the owner PIN
                    to the device when enrolled OR when the owners password is changed.
                  </span>
                </b-popover>
              </div>

            </div>


          </section>

          <section v-if="!loadingNetworks">
            <h3 class="border-bottom mb-1 mt-2 pb-1">Networks</h3>

            <section class="d-flex flex-wrap update-user-modal__roles">
              <label v-for="network in networks" :key="network.uid" class="d-flex col-3">
                <b-form-checkbox
                  v-model="group.network_ids"
                  :value="network.uid"
                />
                {{ network.name }}
              </label>
              <p v-if="networks.length === 0">No networks available</p>
            </section>

          </section>

          <section v-if="!loadingApplications">
            <h3 class="border-bottom mb-1 mt-2 pb-1">Applications</h3>

            <section class="app-columns">
              <div v-for="app in applications" :key="app.id" class="app-item d-flex">
                <b-form-checkbox
                  :id="'app-' + app.id"
                  v-model="group.application_ids"
                  :value="app.id"
                />
                <label class="mb-0" :for="'app-' + app.id">{{ app.name }}</label>
              </div>
              <p v-if="applications.length === 0">No applications available</p>
            </section>
          </section>

        </section>
      </section>

      <section v-else>
        <b-spinner variant="primary" label="Getting group" />
      </section>

    </b-card>
    <section class="d-inline-flex full-width">
      <div class="ui-spacer" />

      <router-link v-if="group" class="btn btn-warning mr-1" tag="button" :to="{ name: 'group-view', params: { uid: group.uid } }">
        Cancel
      </router-link>

      <b-button v-if="group" :class="{'disabled': !checkFormValid()}"
                variant="primary" @click="updateGroup(false)"
      >
        Update group
      </b-button>
    </section>
    <b-modal id="modal-confirm-update" hide-footer>
      <confirm-update-modal :info="errorInfo"
                            @ok="updateGroup(true)" @close="closeModal()">
      </confirm-update-modal>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import GroupService from '../../../services/GroupService'
import ApplicationsService from '../../../services/ApplicationsService'
import NetworkService from '../../../services/NetworkService'
import ConfirmUpdateModal from "@/views/groups/editGroup/ConfirmUpdateModal.vue";
import InsecureWarning from "@/views/groups/other/InsecureWarning.vue";

export default {
  components: {ConfirmUpdateModal, InsecureWarning},
  props: {
    uid: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      loading: true,
      group: null,
      applications: [],
      loadingApplications: true,
      loadingNetworks: true,
      networks: [],
      errorInfo: "",
      warningText: "The current setting of this field is not secure",
    }
  },
  mounted() {
    this.getGroup()
    this.getApplications()
    this.getNetworks()
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('modal-confirm-update')
    },
    showModal(modalName, content) {
      this.errorInfo = content
      this.$bvModal.show(modalName)
    },
    getGroup() {
      this.loading = true

      GroupService.getGroup(this.uid)
        .then(res => {
          this.group = res.data

          // For now override the wifi config enabled setting so that it's always on. This is to ensure hte network escape hatch isn't shown.
          this.group.wifi_config_enabled = true


          if (!this.group.networks) {
            Vue.set(this.group, 'networks', [])
          }
        })
        .catch(err => {
          const res = err.response
          let errorText = 'Could not get group, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
          this.$emit('refresh', true)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getApplications() {
      this.loadingApplications = true

      ApplicationsService.getApplications()
        .then(res => {
          this.applications = res.data.sort((a, b) => {
            if (a.name < b.name) {
              return -1
            }
            if (a.name > b.name) {
              return 1
            }
            return 0
          })
        })
        .catch(err => {
          const res = err.response
          let errorText = 'Could not get applications, you can update existing data in the meantime and try again later'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        })
        .finally(() => {
          this.loadingApplications = false
        })
    },
    getNetworks() {
      this.loadingNetworks = true

      NetworkService.getNetworksSlim()
        .then(res => {
          this.networks = res.data
        })
        .catch(err => {
          const res = err.response
          let errorText = 'Could not get network, you can update existing data in the meantime and try again later'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        })
        .finally(() => {
          this.loadingNetworks = false
        })
    },
    updateGroup(confirm) {
      this.closeModal()
      const group = { ...this.group }
      Vue.delete(group, 'application_ids')
      group.applications = this.group.application_ids
      group.networks = this.group.network_ids

      if (confirm) {
        group.confirm_insecure = true
      }

      GroupService.updateGroup(this.group.uid, group)
        .then(() => {
          this.$toast.success(`Updated group ${this.group.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          })

          this.$router.push({ name: 'group-view', params: { uid: group.uid } });
        })
        .catch(err => {
          const res = err.response
          let errorText = 'Could not update group, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          if(errorText.includes("The following options are making the group insecure")){
            this.showModal('modal-confirm-update', errorText)
          } else {
            this.$toast.error(errorText, {
              toastClassName: ['toast-std', 'warning-toast'],
            })
          }
        })
    },
    checkFormValid() {
      const correctLockRange = this.group.time_to_lock_seconds >= 10 && this.group.time_to_lock_seconds <= 600
      const nameLength = this.group.name.length > 0

      return nameLength && correctLockRange
    },
    getValidationState({
                         dirty,
                         validated,
                         valid = null,
                       }) {
      return dirty || validated ? valid : null
    },
  },
}
</script>

<style>
.app-columns {
  column-width: 200px;
}

.app-item {
  display: inline-block;
  width: 100%;
}
</style>
